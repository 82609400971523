<template>
    <v-navigation-drawer
        v-model="isDrawer"
        app
        dark
        :expand-on-hover="!isMobile"
        :mini-variant="!isMobile"
        color="#1f1d31"
    >
        <template #prepend>
            <v-sheet
                color="#1f1d31"
                height="54"
                class="pl-3 d-flex align-center"
            >
                <v-avatar
                    size="32"
                >
                    <v-img
                        :src="app.logo"
                    />
                </v-avatar>
                <div class="text-truncate white--text pl-4">{{ app.name }}</div>
            </v-sheet>
        </template>
        <template #append>
            <v-list>
                <v-list-item
                    :to="{ name: 'auth.profile' }"
                >
                    <v-list-item-icon>
                        <w-icon
                            value="mdi-account-circle"
                        />
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $trans('Profile') }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
        <v-list>
            <template v-for="(link, i) in mainMenu">
                <v-list-group
                    v-if="link.children"
                    :key="`v-list-group-${i}`"
                    :prepend-icon="link.icon"
                    no-action
                    color="white"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>{{ $trans(link.title) }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-for="(children, j) in link.children">
                        <v-list-item
                            :key="`v-list-item-${i}-${j}`"
                            :to="children.to"
                            exact
                        >
                            <v-list-item-icon>
                                <v-icon>{{ children.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $trans(children.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>
                <v-list-item
                    v-else
                    style="font-weight: normal"
                    :key="`v-list-item-${i}`"
                    :to="link.to"
                    exact
                >
                    <v-list-item-icon>
                        <v-icon medium>{{ link.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-1">{{ $trans(link.title) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import themeConfig from '@themeConfig'
import mainMenuInitMixin from '@/layouts/mixins/mainMenuInitMixin'

export default {
    name: 'LayoutAside',
    mixins: [
        mainMenuInitMixin,
        mobileBreakpointChecker
    ],
    props: {
        value: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        app() {
            return themeConfig.app
        }
    },
    watch: {
        value(flag) {
            this.isDrawer = flag
        },
        isDrawer() {
            this.$emit('input', this.isDrawer)
        }
    },
    data() {
        return {
            isDrawer: false
        }
    },
    mounted() {
        this.isDrawer = this.value
    }
}
</script>

<style lang="scss">
</style>
