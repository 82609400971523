import appsNavs from '@/plugins/appsNavs'

export default [
    {
        title: 'Home',
        icon: 'mdi-home-circle',
        to: { name: 'home' }
    },
    ...appsNavs
]
