/* eslint-disable */
import {
    CAPACITY,
    COURSE,
    DAYBOOK,
    DEPARTMENT,
    DOCUMENTS,
    GROUP,
    QUESTIONS,
    SCHEDULE, STUDENT
} from '@/widgets/components/WIcon/icons'
import { MEMBER_STUDENT, MEMBER_TEACHER } from '@apps/auth/config/members'
import { ROLE_ASSISTANT, ROLE_CURATOR, ROLE_MANAGER, ROLE_SUPER_ADMIN } from '@apps/auth/config/roles'

export default [
    {
        icon: DAYBOOK,
        title: 'Day book',
        to: { name: 'daybook.index' },
        color: 'success',
        access: {
            members: [ MEMBER_TEACHER, MEMBER_STUDENT ]
        }
    },
    {
        icon: SCHEDULE,
        title: 'Schedule',
        to: { name: 'schedule' },
        color: 'success',
        access: {
            members: [ MEMBER_TEACHER, MEMBER_STUDENT ]
        }
    },
    {
        icon: CAPACITY,
        title: 'Capacity',
        to: { name: 'capacity' },
        color: 'success',
        access: {
            members: [ MEMBER_TEACHER ]
        }
    },
    {
        icon: COURSE,
        title: 'Courses',
        to: { name: 'courses' },
        color: 'success',
        access: {
            members: [ MEMBER_TEACHER, MEMBER_STUDENT ]
        }
    },
    // {
    //     icon: STUDENT,
    //     title: 'Students',
    //     to: { name: 'students' },
    //     color: 'success',
    //     access: {
    //         roles: [ ROLE_MANAGER ]
    //     }
    // },
    {
        icon: DEPARTMENT,
        title: 'Departments',
        to: { name: 'departments.index' },
        color: 'success',
        access: {
            roles: [ ROLE_ASSISTANT, ROLE_MANAGER ]
        }
    },
    {
        icon: GROUP,
        title: 'Groups',
        to: { name: 'groups' },
        color: 'success',
        access: {
            members: [ MEMBER_TEACHER ]
        }
    },
    // {
    //     icon: DOCUMENTS,
    //     title: 'Documents',
    //     to: { name: 'documents' },
    //     color: 'success',
    //     access: {
    //         members: [ MEMBER_TEACHER ]
    //     }
    // },
    {
        icon: QUESTIONS,
        title: 'Tests',
        to: { name: 'questions' },
        color: 'success',
        access: {
            members: [ MEMBER_STUDENT ]
        }
    },
    // {
    //     icon: QUESTIONS,
    //     title: 'Tests',
    //     to: { name: 'questions.manage' },
    //     color: 'success',
    //     access: {
    //         members: [ MEMBER_TEACHER ]
    //     }
    // }
]
