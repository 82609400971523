import mainMenu from '../utils/mainMenu'
import EventEmitter from '@/plugins/EventEmitter'

export default {
    computed: {
        mainMenu() {
            /* eslint-disable-next-line */
            const filterAdmission = links => links.filter(link => {
                if (link.submenu && !link.access) {
                    link.submenu = [].concat(filterAdmission(link.submenu))

                    return link.submenu.length > 0
                } else if (!link.access) {
                    return true
                } else {
                    return this.$auth.hasAccess(link.access)
                }
            })

            /* eslint-disable-next-line */
            let navigation = [].concat(mainMenu)

            EventEmitter.emit('init:nav', navigation)

            return filterAdmission(navigation)
        }
    }
}
