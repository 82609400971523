<template>
    <v-app
        class="layout-content"
    >
        <layout-aside
            v-model="isDrawer"
        />
        <layout-app-bar
            @click:nav-icon="isDrawer = !isDrawer"
        />
        <v-main>
            <slot></slot>
        </v-main>
    </v-app>
</template>

<script>
import LayoutAppBar from '@/layouts/AppBar'
import LayoutAside from '@/layouts/Layout/LayoutAside'
export default {
    name: 'LayoutContent',
    components: { LayoutAside, LayoutAppBar },
    data() {
        return {
            isDrawer: null
        }
    }
}
</script>

<style lang=scss>

</style>
