// Register apps navs
const appsNavsFiles = require.context('@/apps', true, /nav.js$/)

export default appsNavsFiles.keys()
    .reduce((navs, path) => {
        const value = appsNavsFiles(path)
        const collection = value.default

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < collection.length; i++) {
            navs.push(collection[i])
        }

        return navs
    }, [])
