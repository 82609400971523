<template>
    <v-app-bar
        app
        flat
        tile
        :height="$store.getters.theme.appBar.height"
    >
        <v-app-bar-nav-icon
            @click="$emit('click:nav-icon')"
        />
        <school-select/>
        <v-spacer></v-spacer>
<!--        <app-bar-theme-switcher/>-->
<!--        <app-bar-i18n />-->
        <auth-menu />
    </v-app-bar>
</template>

<script>
import AuthMenu from '@apps/auth/components/AuthMenu'
import SchoolSelect from '@apps/school/components/School/SchoolSelect'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
export default {
    name: 'AppBar',
    components: { SchoolSelect, AuthMenu },
    mixins: [ mobileBreakpointChecker ]
}
</script>

<style lang=scss>

</style>
