<template>
    <v-select
        v-if="isSelect"
        v-model="selectedSchool"
        outlined
        dense
        :items="schools"
        item-text="title"
        item-value="uuid"
        hide-details
        style="max-width: 300px"
        @change="onChangeSchool"
    >
        <template #prepend-inner>
            <w-icon dense value="SCHOOL"/>
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'SchoolSelect',
    data() {
        return {
            selectedSchool: null
        }
    },
    computed: {
        isSelect() {
            const authCanSee = this.$auth.isAdmin() ||
                this.$auth.isSuperAdmin() ||
                this.$auth.isManager()

            const isSelect = !!this.currentSchool

            return authCanSee && isSelect
        },
        currentSchool() {
            return this.$store.getters['school/currentSchool']
        },
        schools() {
            return this.$store.getters['school/schools']
        }
    },
    watch: {
        currentSchool(item) {
            this.selectedSchool = item.uuid
        }
    },
    mounted() {
        if (this.currentSchool) {
            this.selectedSchool = this.currentSchool.uuid
        }
    },
    methods: {
        async onChangeSchool(uuid) {
            await this.$store.dispatch('school/changeSchool', uuid)

            if (this.$route.name !== 'home') {
                await this.$router.push({ name: 'home' })
            }
        }
    }
}
</script>

<style lang=scss>

</style>
