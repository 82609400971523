<template>
    <v-menu
        v-if="member"
        offset-y
        left
        nudge-bottom="14"
        min-width="230"
        content-class="user-profile-menu-content"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-badge
                bottom
                color="success"
                overlap
                offset-x="12"
                offset-y="12"
                class="ms-4"
                dot
            >
                <member-avatar
                    :item="member"
                    size="32"
                    v-bind="attrs"
                    :on="on"
                />
            </v-badge>
        </template>
        <v-list dense >
            <div class="pb-3 pt-2">
                <v-badge
                    bottom
                    color="success"
                    overlap
                    offset-x="12"
                    offset-y="12"
                    class="ms-4"
                    dot
                >
                    <member-avatar
                        size="42"
                        :item="member"
                    />
                </v-badge>
                <div
                    class="d-inline-flex flex-column justify-center ms-3"
                    style="vertical-align:middle"
                >
                    <span class="text--primary font-weight-semibold mb-n1">
                        {{ member | fullName('short') }}
                    </span>
                    <small class="text-capitalize">{{ $auth.memberType | memberTypeLabel(member.gender) }}</small>
                </div>
            </div>

            <v-divider></v-divider>

            <!-- Profile -->
            <v-list-item
                color="primary"
                :to="{ name: 'auth.profile' }"
            >
                <v-icon size="22" left>
                    mdi-account-outline
                </v-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $trans('Profile') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- Settings -->
            <v-list-item
                color="primary"
                :to="{ name: 'auth.settings' }"
            >
                <v-icon size="22" left>
                    mdi-cog-outline
                </v-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $trans('Settings') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <!-- Logout -->
            <v-list-item @click="logout">
                <v-icon size="22" left>
                    mdi-logout-variant
                </v-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $trans('Sign out') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import MemberAvatar from '@/components/Member/MemberAvatar'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
import hasFiltersOfMemberMixin from '@apps/auth/mixins/hasFiltersOfMemberMixin'
import Debugger from '@/utils/Debugger'
export default {
    name: 'AuthMenu',
    mixins: [
        hasMemberFullNameFilterMixin,
        hasFiltersOfMemberMixin
    ],
    components: { MemberAvatar },
    computed: {
        member() {
            return this.$auth.member
        }
    },
    methods: {
        async logout() {
            try {
                await this.$store.dispatch('auth/logout')
                await this.$router.push({ name: 'auth.login' })
            } catch (errors) {
                Debugger.log(errors)
            }
        }
    }
}
</script>

<style lang="scss">
.user-profile-menu-content {
    .v-list-item {
        min-height: 2.5rem !important;
    }
}
</style>
